import { useTranslation } from 'next-i18next';

import tw from 'twin.macro';

import Modal from '@components/elements/Modal/Modal';

import FinancialOptions from './FinancialOptions';
import PaymentOptions from './PaymentOptions';

const PaymentSummaryModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      styles={{ wrapper: tw`w-full max-w-lg` }}
    >
      <Modal.Header styles={{ heading: tw`text-inherit` }}>
        {t('$*components.paymentSummaryModal.title', 'Podsumowanie płatności')}
      </Modal.Header>
      <Modal.Content>
        <h3 className="h3" tw="mb-4 mt-2">
          {t('$*newOrderCreatePage.orderForm.summary.title', 'Opcje finansowe')}
        </h3>
        <FinancialOptions />
        <h3 className="h3" tw="pt-8 lg:(mb-2 mt-2) border-t border-gray-1">
          {t(
            '$*newOrderCreatePage.orderForm.summary.paymentTypes',
            'Sposoby płatności'
          )}
        </h3>
        <PaymentOptions />
      </Modal.Content>
    </Modal>
  );
};

export default PaymentSummaryModal;
