import { useTranslation } from 'next-i18next';

import { useDispatch, useSelector } from 'react-redux';

import Button from '@components/elements/Button';
import Tooltip from '@components/elements/Tooltip';
import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import {
  nextStep,
  selectIsNextDisabled,
} from '@features/orderTabs/orderTabsSlice';
import { useAppMode } from '@hooks/useAppConfigSelectors';

const BasketAction = ({
  isMobile = false,
  buttonNextText = null,
  styles = {},
  isMutatingBasket,
  currentDiet,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDietFull } = useAppMode();
  const { dietLength, deliveryDates = [] } = currentDiet || {};

  const isFullCalendarValid = () => {
    if (!isDietFull) return true;

    return deliveryDates?.length === dietLength;
  };

  const isNextDisabled = useSelector(selectIsNextDisabled);
  const isValidNextStep =
    isFullCalendarValid() && !isNextDisabled && !isMutatingBasket;

  const handleMobileAction = () => {
    window.scrollTo({ top: 0 });

    if (isMobile) {
      // handleCloseBasket();
    }
  };

  const handleNextStep = () => {
    dispatch(nextStep());
    handleMobileAction();
  };

  if (!isFullCalendarValid()) {
    return (
      <Tooltip
        content={t(
          '$*components.basket.dietLengthIsInvalid',
          'Wybierz poprawną ilość dni na kalendarzu'
        )}
      >
        <div>
          <Button
            fullWidth={true}
            onClick={handleNextStep}
            isLoading={isMutatingBasket}
            disabled={!isValidNextStep}
            data-cy="basket-action__button--next"
            id="order-form-next-button"
            styles={{
              button: styles?.nextButton,
            }}
          >
            {buttonNextText ?? t('$*components.basket.next', 'Dalej')}
          </Button>
        </div>
      </Tooltip>
    );
  }

  return (
    <Button
      fullWidth={true}
      onClick={handleNextStep}
      isLoading={isMutatingBasket}
      disabled={!isValidNextStep}
      data-cy="basket-action__button--next"
      id="order-form-next-button"
      styles={{
        button: styles?.nextButton,
      }}
    >
      {buttonNextText ?? t('$*components.basket.next', 'Dalej')}
    </Button>
  );
};

export default withMemoBasketMethods(BasketAction, [
  'isMutatingBasket',
  'currentDiet',
]);
