import { useTranslation } from 'next-i18next';

import isEmpty from 'lodash/isEmpty';

import {
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';
import useDiscountCodeHook from '@hooks/useDiscountCode';

const DiscountCodeBadges = () => {
  const { t } = useTranslation();
  const { currencySymbol } = useAppConfigSelector(selectMultinational);

  const {
    basketStore: { useDiscountCode },
  } = useBasketMethods();

  const { data: discount = {} } = useDiscountCodeHook();

  const hasDiscount = !isEmpty(discount);

  if (!hasDiscount || !useDiscountCode) {
    return null;
  }

  return (
    <div tw="flex flex-wrap gap-2 mt-2 md:mt-4 text-xs text-white">
      <div tw="bg-blue-1 rounded-md px-2 py-1">
        {`${t(
          '$*newOrderCreatePage.orderForm.summary.grantedDiscount',
          'Rabat'
        )} -${discount.discount}${
          discount?.isPercentage ? '%' : currencySymbol
        }`}
      </div>
      {discount?.applyToAddons === false && (
        <div tw="bg-yellow-0 rounded-md px-2 py-1">
          {t(
            '$*components.discountCode.noApplyToAddons',
            'Rabat nie obejmuje dodatków.'
          )}
        </div>
      )}
      {discount?.disableDietDiscount === true && (
        <div tw="bg-yellow-0 rounded-md px-2 py-1">
          {t(
            '$*components.discountCode.disableDietDiscount',
            'Rabat nie łączy się z innymi promocjami.'
          )}
        </div>
      )}
    </div>
  );
};

export default DiscountCodeBadges;
