import dynamic from 'next/dynamic';

import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';

const BasketDiet = dynamic(() => import('./BasketDiet'));
const EmptyBasket = dynamic(() => import('../BasketContent/EmptyBasket'));
const DiscountCode = dynamic(() =>
  import('@components/elements/DiscountCode/DiscountCode')
);

const BasketContent = ({
  isEmptyBasket,
  dietElements,
  dietsPrices,
  testMode,
  // context
}) => {
  const { showDiscountCodeInputInBasket } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  if (isEmptyBasket) {
    return <EmptyBasket />;
  }

  return (
    <div
      className="basket__content"
      tw="overflow-auto pb-0.5"
      style={{ maxHeight: 'calc(100vh - 305px)' }}
    >
      {dietElements.map(diet => (
        <BasketDiet key={diet['@id']} diet={diet} {...dietsPrices[0]} />
      ))}
      {showDiscountCodeInputInBasket && <DiscountCode testMode={testMode} />}
    </div>
  );
};

export default withMemoBasketMethods(BasketContent, [
  'testMode',
  'basketStore',
  'commonSetDiscountCode',
  'setDiscountCode',
  'updateOrderBasketModify',
  { as: 'basketData', path: 'basketQuery.data', defaultValue: {} },
  { as: 'dietElements', path: 'basketStore.items.dietElements' },
]);
