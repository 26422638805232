import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectInitialDiet } from '@store/basket/basketInitialData.slice';
import { useIsMutating } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import tw from 'twin.macro';

import Button from '@components/elements/Button';
import Tooltip from '@components/elements/Tooltip';
import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import BASKET_PAYMENT_MODES from '@constants/basketPaymentModes';
import ROUTE_URLS from '@constants/routeUrls';
import {
  changeStep,
  changeStepAndResetValid,
  nextStep,
  selectIsNextDisabled,
  selectTabs,
} from '@features/orderTabs/orderTabsSlice';
import useTriggerGtmEvents from '@hooks/basket/useTriggerGtmEvents';
import { useAppMode } from '@hooks/useAppConfigSelectors';
import { pickRenameKeysInitialDiet } from '@services/Basket.service';
import { isCMSPage, isDietPage } from '@utils/helpers';

import PaymentSummaryModal from './PaymentSummaryModal';

const BasketAction = ({
  isMobile = false,
  buttonNextText = null,
  styles = {},

  // basket
  basketStore,
  closePaymentSummaryModal,
  currentDiet,
  handleAddBasketDiet,
  isBasketEditOrder,
  isMutatingBasket,
  isPaymentSummaryModalOpen,
  openPaymentSummaryModal,
  setStatusBasket,
  testMode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();

  const { isShop, isDietFull } = useAppMode();

  const { currentStepIndex, steps, stepsCount } = useSelector(selectTabs);
  const initialDiet = useSelector(selectInitialDiet);

  const triggerGtmEvents = useTriggerGtmEvents();

  const { dietLength, paymentMode, deliveryDates = [] } = currentDiet;
  const isSubscription =
    paymentMode === BASKET_PAYMENT_MODES.SUBSCRIPTION_PAYMENT;

  const isFullCalendarValid = () => {
    if (!isDietFull) return true;

    return deliveryDates?.length === dietLength;
  };

  const isMutatingBasketNewOrder =
    useIsMutating({
      mutationKey: ['basketNewOrder'],
    }) === 1;

  const isNextDisabled = useSelector(selectIsNextDisabled);
  const basketIsValidToPayment = basketStore.isValid && isFullCalendarValid();
  const isValidNextStep =
    !isNextDisabled &&
    basketIsValidToPayment &&
    !isMutatingBasket &&
    !isMutatingBasketNewOrder;
  const isLastStep = currentStepIndex === stepsCount - 1;

  const handleCloseBasket = () => dispatch(setStatusBasket(false));
  const handleMobileAction = () => {
    window.scrollTo({ top: 0 });

    if (isMobile) {
      handleCloseBasket();
    }
  };

  const handleNextStep = () => {
    dispatch(nextStep());

    triggerGtmEvents();

    handleMobileAction();
  };

  const handleAddMore = async () => {
    if (isDietFull) {
      await handleAddBasketDiet(pickRenameKeysInitialDiet(initialDiet));

      dispatch(changeStepAndResetValid(0));
      handleMobileAction();
    } else {
      dispatch(changeStep(0));
      handleMobileAction();
    }
  };

  if (isCMSPage(router.pathname)) {
    return (
      <Button
        onClick={async () => {
          if (isDietPage(router.pathname)) {
            await router.push({
              pathname: ROUTE_URLS.NEW_ORDER_FORM,
              query: { step: steps?.length > 1 ? steps[1]?.slug : '' },
            });
          } else {
            await router.push(ROUTE_URLS.NEW_ORDER_FORM);
          }
          if (isMobile) {
            handleCloseBasket();
          }
        }}
        fullWidth={true}
        isLoading={isMutatingBasket}
        data-cy="basket-action__button--next"
        id="order-form-next-button"
        styles={{
          button: styles?.nextButton,
        }}
        disabled={isEmpty(currentDiet)}
      >
        {buttonNextText ?? t('$*components.basket.next', 'Dalej')}
      </Button>
    );
  }

  if (isBasketEditOrder) {
    return (
      <Fragment>
        <Button
          fullWidth={true}
          onClick={openPaymentSummaryModal}
          isLoading={isMutatingBasket}
          data-cy="basket-action__button--pay"
        >
          {t('$*components.basket.pay', 'Zapłać')}
        </Button>
        <PaymentSummaryModal
          isOpen={isPaymentSummaryModalOpen}
          onClose={closePaymentSummaryModal}
        />
      </Fragment>
    );
  }

  if (isShop && isSubscription && !isLastStep) {
    return (
      <Tooltip
        content={t(
          '$*components.basket.removeDishesShopSubscription',
          'Płatność subskrypcyjna jest niedostępna dla wybranych elementów koszyka. Usuń wszystkie dania i dodatki lub zmień sposób płatności.'
        )}
        disabled={basketIsValidToPayment}
      >
        <div>
          <Button
            fullWidth={true}
            onClick={handleNextStep}
            isLoading={isMutatingBasket}
            disabled={!isValidNextStep}
            data-cy="basket-action__button--next"
            id="order-form-next-button"
            styles={{
              button: styles?.nextButton,
            }}
          >
            {buttonNextText ?? t('$*components.basket.next', 'Dalej')}
          </Button>
        </div>
      </Tooltip>
    );
  }

  if (isLastStep) {
    return (
      <>
        {(isShop || isDietFull) && !testMode ? (
          <Button
            className="basket-action__button--add-more"
            fullWidth={true}
            onClick={handleAddMore}
            variant="outlined"
            data-cy="basket-action__button--add-more"
            styles={{ button: tw`bg-white` }}
          >
            {t('$*components.basket.addMore', 'Dodaj więcej')}
          </Button>
        ) : null}
      </>
    );
  }

  if (!isFullCalendarValid()) {
    return (
      <Tooltip
        content={t(
          '$*components.basket.dietLengthIsInvalid',
          'Wybierz poprawną ilość dni na kalendarzu'
        )}
      >
        <div>
          <Button
            fullWidth={true}
            onClick={handleNextStep}
            isLoading={isMutatingBasket}
            disabled={!isValidNextStep}
            data-cy="basket-action__button--next"
            id="order-form-next-button"
            styles={{
              button: styles?.nextButton,
            }}
          >
            {buttonNextText ?? t('$*components.basket.next', 'Dalej')}
          </Button>
        </div>
      </Tooltip>
    );
  }

  return (
    <Button
      fullWidth={true}
      onClick={handleNextStep}
      isLoading={isMutatingBasket}
      disabled={!isValidNextStep}
      data-cy="basket-action__button--next"
      id="order-form-next-button"
      styles={{
        button: styles?.nextButton,
      }}
    >
      {buttonNextText ?? t('$*components.basket.next', 'Dalej')}
    </Button>
  );
};

export default withMemoBasketMethods(BasketAction, [
  { as: 'testMode', path: 'basketQuery.data.testMode' },
  'basketStore',
  'closePaymentSummaryModal',
  'currentDiet',
  'handleAddBasketDiet',
  'isBasketEditOrder',
  'isMutatingBasket',
  'isPaymentSummaryModalOpen',
  'openPaymentSummaryModal',
  'setStatusBasket',
]);
