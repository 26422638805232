import dynamic from 'next/dynamic';

import { useDispatch } from 'react-redux';

import PaymentTypes from '@components/elements/PaymentTypes/PaymentTypes';
import {
  selectBranding,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';
import { pushAddPaymentInfoGTMEvent } from '@utils/gtm';

const BasketPay = dynamic(() => import('./BasketPay'));

const PaymentOptions = ({ currentStepNumber, showBasketPay = true }) => {
  const dispatch = useDispatch();
  const { name: brandName } = useAppConfigSelector(selectBranding);
  const {
    defaultRegion: { currencyCode },
  } = useAppConfigSelector(selectMultinational);

  const {
    basketQuery: { data: basket = {} },
    basketStore: { discountCode, useDiscountCode },
    setPaymentMethod,
    updateOrderBasketModify,
  } = useBasketMethods();

  const paymentType = basket?.paymentType ?? null;
  const paymentTypes = basket?.paymentTypes ?? [];

  const handleClickPaymentType = ({ paymentType, metadata = {} }) => {
    pushAddPaymentInfoGTMEvent({
      brandName,
      currencyCode,
      discountCode: basket?.discountCode?.code,
      items: basket?.rows,
      paymentType,
      price: basket?.price?.afterDiscount,
    });

    updateOrderBasketModify({
      payload: {
        paymentType,
        paymentMetadata: metadata,
        ...(useDiscountCode ? { discountCode: discountCode?.['@id'] } : {}),
      },
      stepNumber: currentStepNumber,
    });

    dispatch(setPaymentMethod(paymentType));
  };
  return (
    <>
      <PaymentTypes
        payments={Object.values(paymentTypes)}
        selectedPaymentType={paymentType}
        onClickPayment={handleClickPaymentType}
      />
      {showBasketPay && <BasketPay />}
    </>
  );
};

export default PaymentOptions;
