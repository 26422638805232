import dynamic from 'next/dynamic';

import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';

const BasketNormal = dynamic(() => import('./BasketNormal/BasketNormal'));
const BasketStatic = dynamic(() => import('./BasketStatic/BasketStatic'));

const Basket = ({ isBasketStatic }) => {
  if (isBasketStatic) {
    return <BasketStatic />;
  }

  return <BasketNormal />;
};

export default withMemoBasketMethods(Basket, ['isBasketStatic']);
