import dynamic from 'next/dynamic';

import isEmpty from 'lodash/isEmpty';

import withMemoBasketMethods from '@components/HOC/withMemoBasketMethods';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';

import EmptyBasket from '../BasketContent/EmptyBasket';

const BasketDiet = dynamic(() => import('./BasketDiet'));
const DiscountCode = dynamic(() =>
  import('@components/elements/DiscountCode/DiscountCode')
);
const BasketDietDay = dynamic(() => import('./BasketDietDay'));
const BasketModificationList = dynamic(() =>
  import('./BasketModification/BasketModificationList')
);

const BasketContent = ({ basketData, isEmptyBasket }) => {
  const { testMode, uiRows = {} } = basketData;
  const { showDiscountCodeInputInBasket } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  if (isEmptyBasket) {
    return <EmptyBasket />;
  }

  return (
    <div
      className="basket__content"
      tw="overflow-auto pb-0.5"
      style={{ maxHeight: 'calc(100vh - 305px)' }}
    >
      <div tw="pb-2">
        {uiRows.diets.map(diet => (
          <BasketDiet key={diet['@id']} diet={diet} />
        ))}
      </div>
      <BasketModificationList modifications={uiRows.modifications} />
      {!isEmpty(uiRows.others) && (
        <div>
          {Object.entries(uiRows.others).map(([dietIri, days]) => (
            <BasketDietDay key={dietIri} days={days} dietIri={dietIri} />
          ))}
        </div>
      )}
      {showDiscountCodeInputInBasket && <DiscountCode testMode={testMode} />}
    </div>
  );
};

export default withMemoBasketMethods(BasketContent, [
  { as: 'basketData', path: 'basketQuery.data', defaultValue: {} },
  'setDiscountCode',
  'commonSetDiscountCode',
  'updateOrderBasketModify',
  'isEmptyBasket',
  'testMode',
]);
