import DiscountCode from '@components/elements/DiscountCode/DiscountCode';
import DiscountCodeBadges from '@components/elements/DiscountCode/DiscountCodeBadges';
import Invoice from '@components/elements/Invoice/Invoice';
import PiggyBankPoints from '@components/elements/PiggyBankPoints/PiggyBankPoints';
import {
  selectModuleConfigClientPanel,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';

const FinancialOptions = () => {
  const { allowClientPayWithMoneboxEverywhere } = useAppConfigSelector(
    selectModuleConfigClientPanel
  );

  const {
    basketQuery: { data: { price = {} } = {} },
    basketStore: { invoice, useInvoice, useMoneyBox },
    setInvoice,
    setUseMoneyBox,
    updateOrderBasketModify,
    testMode,
  } = useBasketMethods();

  return (
    <div tw="-my-4 pt-4 pb-8">
      <div tw="border-b pb-4 mb-4 lg:(pb-7 mb-7)">
        <DiscountCode testMode={testMode} />
        <DiscountCodeBadges />
      </div>
      {allowClientPayWithMoneboxEverywhere && (
        <PiggyBankPoints
          updateOrderBasketModify={updateOrderBasketModify}
          useMoneyBox={useMoneyBox}
          setUseMoneyBox={setUseMoneyBox}
        />
      )}
      {price?.afterDiscount > 0 && (
        <Invoice
          invoice={invoice}
          useInvoice={useInvoice}
          setInvoice={setInvoice}
        />
      )}
    </div>
  );
};

export default FinancialOptions;
