import { useSelector } from 'react-redux';

import TAB_SLUGS from '@constants/tabSlugs';
import { selectTabs } from '@features/orderTabs/orderTabsSlice';
import {
  selectBranding,
  selectMultinational,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useBasketMethods from '@hooks/useBasketMethods';
import { pushAddShippingInfoGTMEvent } from '@utils/gtm';

const useTriggerGtmEvents = () => {
  const { name: brandName } = useAppConfigSelector(selectBranding);
  const { currentStepSlug } = useSelector(selectTabs);

  const {
    defaultRegion: { currencyCode },
  } = useAppConfigSelector(selectMultinational);

  const {
    currentDiet: { deliveryType },
    basketQuery: { data: basket },
  } = useBasketMethods();

  return () => {
    switch (currentStepSlug) {
      case TAB_SLUGS.DELIVERY_DATA:
        pushAddShippingInfoGTMEvent({
          brandName,
          currencyCode,
          discountCode: basket?.discountCode?.code,
          items: basket?.rows,
          price: basket?.price?.afterDiscount,
          shipping: deliveryType,
        });
        break;

      default:
        break;
    }
  };
};

export default useTriggerGtmEvents;
