import { useTranslation } from 'next-i18next';

import SadShoppingBasketIcon from '@assets/icons/SadShoppingBasketIcon';

const EmptyBasket = () => {
  const { t } = useTranslation();

  return (
    <div tw="text-center pt-2">
      <SadShoppingBasketIcon tw="w-12 mx-auto" />
      <div tw="pt-3">
        {t('$*components.basket.emptyBasket', 'Koszyk aktualnie jest pusty')}
      </div>
    </div>
  );
};

export default EmptyBasket;
